import React,{useState} from 'react';
import {Button,Nav,Card,Container,ListGroup,Collapse} from 'react-bootstrap';

function App() {
    const [section, setSection] = useState('home');
    const sections = [
        {
            name:"education",
            variant:"success"
        },
        {
            name:"work",
            variant:"primary"
        },
        {
            name:"projects",
            variant:"info"
        },
        {
            name:"contact",
            variant:"warning"
        }
    ];

    const content = {
        education:[
            (<>

                <h3 className={"mr-2"}>
                    Lincoln Technical Institute
                    <small className={"text-muted"}> 2015</small>
                </h3>
                <ul className={"list-inline font-weight-bold"}>
                    <li className={"list-inline-item"}>AS Electronics Engineering Technology</li>
                </ul>
                <p>Analyzed electronic circuits and solved problems related to the circuit analysis</p>
                <p>Worked with PLCs, digital microprocessors, integrated circuits, and analog components.</p>
                <p className={"font-weight-strong"}>
                    Designed and Built a Color Detector utilizing an Arduino, CMY LEDs, shift registers, and Multiple LDRs
                </p>

            </>),
            (<>

                    <h3 className={"mr-2"}>
                        Emmaus High School
                    <small className={"text-muted"}> 2014</small>
                    </h3>

                <p>Took extra yearly coursework in computer science, learning and aiding students during lab.</p>
                <p>Aided in upkeep of school newspaper's wordpress news site.</p>

            </>),
        ],
        work:[
            (<>
                <a rel="noopener noreferrer" href={"#"} target={"_BLANK"}>
                    <h3>
                        Swift Electric
                        <small className={"text-muted"}> 2021 - Present</small>
                    </h3>
                </a>
                <ul className={"list-inline font-weight-bold"}>
                    <li className={"list-inline-item"}>Apprentice Electrician</li>
                    <li className={"list-inline-item"}>Controls Systems Installation & Service</li>
                </ul>
                <p>Installation and Service of Residential, Commercial, and Industrial Electrical Systems</p>
            </>),
            (<>
                <a rel="noopener noreferrer" href={"http://walkertechnical.com/"} target={"_BLANK"}>
                    <h3>
                        Walker Technical Company
                        <small className={"text-muted"}> 2015 - 2019</small>
                    </h3>
                </a>
                <ul className={"list-inline font-weight-bold"}>
                    <li className={"list-inline-item"}>Manufacturing Technician</li>
                    <li className={"list-inline-item"}>Software Developer</li>
                    <li className={"list-inline-item"}>Service & Installation Lead</li>
                </ul>
                <p>Manufacturing of electromechanical systems.</p>
                <p>Overhauled existing Employee Timeclock system with a new web application utilizing node.js, express.js,jQuery, and socket.io</p>
                <p>
                    Worked with customers to draft, proof, and plot custom text and imagery onto plastics and other materials with a CNC laser. performed preventative maintenance on the laser system.
                </p>
                <p>
                    Completed installations of systems on customer sites: Mounted speakers, ran and terminated cat5, performed initial setup, and final checkout.
                </p>
                <p>
                    Responded to routine or emergency service calls for equipment across the continental United States. Worked with the customer to identify possible causes and solutions to arrive prepared with possible replacement parts or harnesses.
                </p>
            </>),
            (<>
                <h3>
                    KyleRun Technology Solutions
                    <small className={"text-muted"}> 2016 - </small>
                </h3>
                <ul className={"list-inline font-weight-bold"}>
                    <li className={"list-inline-item"}>Website Admin</li>
                    <li className={"list-inline-item"}>Software Solutions Consultant</li>
                    <li className={"list-inline-item"}>Self Employed</li>
                </ul>
                <p>
                    Sideline business providing website based solutions to small business & family.
                </p>
            </>),
            (<>
                <h3>
                    Prestige Landsculpting
                    <small className={"text-muted"}> 2017 - 2018</small>
                </h3>
                <ul className={"list-inline font-weight-bold"}>
                    <li className={"list-inline-item"}>Landscape Crew Member</li>
                    <li className={"list-inline-item"}>Administrative Assistant</li>
                </ul>
                <p>
                    Utilized zero turn mowers, string trimmers, blowers, and other hard tools to assist teams in completing work.
                </p>
                <p>
                    Provided Administrative assistance includ3ing payroll and account billing.
                </p>
            </>),
            (<>
                <h3>
                    Kuhnsville Carwash
                    <small className={"text-muted"}> 2013 - 2014</small>
                </h3>
                <ul className={"list-inline font-weight-bold"}>
                    <li className={"list-inline-item"}>Lane Lead</li>
                    <li className={"list-inline-item"}></li>
                </ul>
                <p>
                    Performed detailed work on customers vehicles. Cleaning interiors and exteriors.
                </p>
                <p>
                    Coordinated flow of vehicles over radio communications with fellow lane leads.
                </p>
            </>),
            (<>
                <h3>
                    Armetta's Restaurant
                    <small className={"text-muted"}> 2013 - 2015</small>
                </h3>
                <ul className={"list-inline font-weight-bold"}>
                    <li className={"list-inline-item"}>Delivery Driver</li>
                    <li className={"list-inline-item"}>Dishwasher</li>
                </ul>
                <p>
                    Worked part time at local pizza place, delivered pizzas, folded boxes, washed dishes.
                </p>

            </>),
            (<>
                <h3>
                    Indian Creek Golf Course
                    <small className={"text-muted"}> 2012 - 2014</small>
                </h3>
                <ul className={"list-inline font-weight-bold"}>
                    <li className={"list-inline-item"}>Turfgrass Technician</li>
                    <li className={"list-inline-item"}>Assistant Shopkeeper</li>
                </ul>
                <p>
                    Utilized commercial equipment to perform daily and routine maintenance of golf course turf.
                </p>
                <p>
                    Aided in shopkeeping of golf course pro shop.
                </p>
            </>)

        ],
        projects:[
            (<>
                <a href={"https://twrd.io/"} rel="noopener noreferrer" target={"_BLANK"}>
                    <h3>
                        TWRD.IO
                        <small className={"text-muted"}> 2020 - </small>
                    </h3>
                </a>
                <ul className={"list-inline"}>
                    <li className={"list-inline-item"}>Javascript</li>
                    <li className={"list-inline-item"}>React.js</li>
                    <li className={"list-inline-item"}>JQuery</li>
                    <li className={"list-inline-item"}>Php</li>
                    <li className={"list-inline-item"}>SQL</li>
                </ul>
                <p>
                    Online Tower Defense Builder / Game Player
                </p>
            </>),
            (<>

                <h3>
                    Appointment Scheduling
                    <small className={"text-muted"}> Safe Breast Scan 2019 - </small>
                </h3>
                <ul className={"list-inline"}>
                    <li className={"list-inline-item"}>Wordpress</li>
                    <li className={"list-inline-item"}>Node.js</li>
                    <li className={"list-inline-item"}>JQuery</li>
                    <li className={"list-inline-item"}>SQL</li>
                    <li className={"list-inline-item"}>Bootstrap</li>
                </ul>

                <p>
                    Backend Web application enabling independent technicians to handle their own appointments, maintaining a database of patients, appointments, and locations.
                    Sends out notifications and alerts to patients of appointment details or changes.
                </p>
            </>),
            (<>

                <h3>
                    TimeClock
                    <small className={"text-muted"}> Walker Technical Company 2017 - 2019</small>
                </h3>
                <ul className={"list-inline"}>
                    <li className={"list-inline-item"}>Javascript</li>
                    <li className={"list-inline-item"}>Node.js</li>
                    <li className={"list-inline-item"}>JQuery</li>
                    <li className={"list-inline-item"}>Socket.io</li>
                    <li className={"list-inline-item"}>SQL</li>
                    <li className={"list-inline-item"}>Bootstrap</li>
                </ul>

                <p>
                    Web application for the employees to allocate their time towards specific tasks, work orders, or projects.
                </p>
            </>),
            (<>

                <h3>
                    Rodgers Inventory
                    <small className={"text-muted"}> Walker Technical Company 2019</small>
                </h3>
                <ul className={"list-inline"}>
                    <li className={"list-inline-item"}>Javascript</li>
                    <li className={"list-inline-item"}>Node.js</li>
                    <li className={"list-inline-item"}>JQuery</li>
                    <li className={"list-inline-item"}>SQL</li>
                    <li className={"list-inline-item"}>Bootstrap</li>
                </ul>

                <p>
                    Inventory Database & Management system made for keeping record of parts related to Rodgers Organ.
                </p>
            </>),
            (<>

                <h3>
                    Autovent Commander
                    <small className={"text-muted"}> KyleRun 2018</small>
                </h3>
                <ul className={"list-inline"}>
                    <li className={"list-inline-item"}>Raspberry Pi</li>
                    <li className={"list-inline-item"}>Node.js</li>
                    <li className={"list-inline-item"}>Linux</li>
                    <li className={"list-inline-item"}>MongoDB</li>
                    <li className={"list-inline-item"}>Serial Com</li>
                </ul>

                <p>
                    Created image to be deployed to raspberry pi's which would be located adjacent to existing SplatCo controllers which monitor and control aspects of weather and farming equipment.
                    The new Pi would then communicate over serial to the controller and send data back to a mongodb server.
                </p>
            </>),
            (<>
                <a rel="noopener noreferrer" target={"_BLANK"} href={"https://safebreastscan.com/"}>
                    <h3>
                        Safe Breast Scan
                        <small className={"text-muted"}> KyleRun 2020</small>
                    </h3>
                </a>
                <ul className={"list-inline"}>
                    <li className={"list-inline-item"}>WordPress</li>
                </ul>

                <p>
                    Website for business offering thermography services.
                </p>
            </>),
            (<>
                <a rel="noopener noreferrer" target={"_BLANK"} href={"https://fetchbozeman.com/"}>
                    <h3>
                        FetchK9 LLC
                        <small className={"text-muted"}> KyleRun 2020</small>
                    </h3>
                </a>
                <ul className={"list-inline"}>
                    <li className={"list-inline-item"}>WordPress</li>
                </ul>

                <p>
                    Website for local business offering dog training and walking services.
                </p>
            </>)

        ],
        contact:[
            (
                <Card>
                    <Card.Header>
                        Kyle Reverie
                    </Card.Header>
                    <Card.Body>
                        <p>
                        <a href={"mailto:kyle@kyle.run"}>
                            kyle@kyle.run
                        </a>
                        </p>
                        <p>
                        <a href={"tel:+14848661199"}>
                            484 866 1199
                        </a>
                        </p>
                    </Card.Body>

                </Card>

            )
        ]

    };
  return (
    <>
        <div className={"display-2 mb-1 text-center"}>
            Kyle
        </div>
        <Container>
            <Nav className={"justify-content-center"}>
                {sections.map((sect)=>(
                    <Button
                        className={"mx-auto my-2"}
                        variant={"outline-" + sect.variant}
                        active={section === sect.name}
                        onClick={
                            section === sect.name ?
                                ()=>{
                                setSection('home')
                            } : ()=>{
                                setSection(sect.name)
                            }}
                        aria-controls={sect.name+'-sect-collapse'}
                        aria-expanded={section === sect.name}
                    >
                        {upperFirst(sect.name)}
                    </Button>
                ))}
            </Nav>
        </Container>
        <Container>
            {Object.keys(content).map((sect, i)=>(
                <Collapse in={sect === section || section === 'home'} key={i}>
                    <div id={sect+'-sect-collapse'} className={"my-3"}>
                        {section === "home" ? (<p className={"h2 text-center"}><u> {upperFirst(sect)} </u></p>):(<></>)}
                        <ListGroup id={sect+'-sect-fade'} variant={"flush"}>
                            {content[sect].map((item)=>(
                                <ListGroup.Item key={sect}>
                                    {item}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </Collapse>
            ))}

        </Container>
    </>
  );
}

function upperFirst(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export default App;
